import { Link } from 'gatsby';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

const StyledHeader = styled.header`
  background-color: ${({ theme }) => theme.illuminatingEmerald()};
  box-sizing: border-box;
  padding: 1rem 2rem;
  box-shadow: ${({ theme }) => theme.illuminatingEmerald(0.5)} 0 5px 4px;
  z-index: 1;
`;

const StyledHeaderTitle = styled.h1`
  font-size: 5rem;
  line-height: 7rem;
`;

const StyledLink = styled(Link)`
  display: inline-block;
  color: ${({ theme }) => theme.darkJungleGreen()};
  text-decoration: none;
  > .focusHoverText {
    display: none;
  }
  > .normalText {
    display: inline;
  }
  &:hover,
  &:focus {
    > .focusHoverText {
      display: inline;
    }
    > .normalText {
      display: none;
    }
  }
`;

class Header extends PureComponent {
  render() {
    return (
      <StyledHeader>
        <StyledHeaderTitle>
          <StyledLink to={`/`}>{this.props.title}</StyledLink>
        </StyledHeaderTitle>
      </StyledHeader>
    );
  }
}

export default Header;
