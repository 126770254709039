import React from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import Header from './Header.js';
import Footer from './Footer.js';
import { Helmet } from 'react-helmet';
import theme from './../theme';
import { StaticQuery, graphql } from 'gatsby';

require('typeface-bad-script');
require('typeface-cormorant-garamond');

const GlobalStyle = createGlobalStyle`
    *:focus {
      outline: none;
    }
    html {
        font-size: 20px;
    }
    body {
        margin: 0;
        font-family: 'Cormorant Garamond', serif;
        font-size: 1rem;
        line-height: 1.3;
        color: #150e04;
    }
    p {
        font-family: 'Cormorant Garamond', serif;
        margin: 0;
        font-size: 1rem;
        line-height: 1.2;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
        font-family: 'Bad Script', serif;
        font-weight: 400;
    }
`;

const GridWrapper = styled.div`
  display: grid;
  min-width: 100vw;
  min-height: 100vh;
  width: 100vw;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
`;

const StyledMain = styled.main`
  background-color: ${({ theme }) => theme.gainsboro()};
`;

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="elenimade.com" />
          <title>{data.site.siteMetadata.title}</title>
          <html lang="en" />
        </Helmet>
        <ThemeProvider theme={theme}>
          <React.Fragment>
            <GlobalStyle />
            <GridWrapper>
              <Header title={data.site.siteMetadata.title} />
              <StyledMain>{children}</StyledMain>
            </GridWrapper>
            <Footer />
          </React.Fragment>
        </ThemeProvider>
      </React.Fragment>
    )}
  />
);
