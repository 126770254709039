export default {
  gainsboro: (alpha = 1) => `rgba(215, 222, 220, ${alpha})`,
  quinacridoneMagenta: (alpha = 1) => `rgba(150, 61, 90, ${alpha})`,
  glossyGrape: (alpha = 1) => `rgba(158, 141, 196, ${alpha})`,
  cyanAzure: (alpha = 1) => `rgba(82, 128, 183, ${alpha})`,
  zomp: (alpha = 1) => `rgba(55, 147, 146, ${alpha})`,
  aeroBlue: (alpha = 1) => `rgba(211, 248, 226, ${alpha})`,
  mauve: (alpha = 1) => `rgba(228, 193, 249, ${alpha})`,
  pastelMagenta: (alpha = 1) => `rgba(246, 148, 193, ${alpha})`,
  pageGoldenrod: (alpha = 1) => `rgba(237, 231, 177, ${alpha})`,
  nonPhotoBlue: (alpha = 1) => `rgba(169, 222, 249, ${alpha})`,
  metallicSeaweed: (alpha = 1) => `rgba(2, 128, 144, ${alpha})`,
  mediumTurqoise: (alpha = 1) => `rgba(82, 209, 220, ${alpha})`,
  illuminatingEmerald: (alpha = 1) => `rgba(54, 143, 139, ${alpha})`,
  englishGreen: (alpha = 1) => `rgba(24, 85, 89, ${alpha})`,
  darkJungleGreen: (alpha = 1) => `rgba(9, 35, 39, ${alpha})`,
  sunsetOrange: (alpha = 1) => `rgba(235, 94, 85, ${alpha})`,
  deepSpaceSparkle: (alpha = 1) => `rgba(58, 96, 110, ${alpha})`,
  fawn: (alpha = 1) => `rgba(215, 175, 112, ${alpha})`,
  cyberGrape: (alpha = 1) => `rgba(97, 63, 117, ${alpha})`,
  pewterBlue: (alpha = 1) => `rgba(156, 175, 183, ${alpha})`
};
