import React, { PureComponent } from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  background-color: ${({ theme }) => theme.illuminatingEmerald()};
  box-sizing: border-box;
  padding: 1rem 2rem;
  text-align: center;
`;

const StyledFooterText = styled.p``;

class Footer extends PureComponent {
  render() {
    return (
      <StyledFooter>
        These things are bankers!
      </StyledFooter>
    );
  }
}

export default Footer;
